import {BrowserRouter} from "react-router-dom";
import ReactDOM from "react-dom";
import {I18nextProvider} from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import i18n from "./translation";
// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBTZuE8oEcFMx17aOcdhm3pl4f82pGGjb4",
    authDomain: "threadhooks.firebaseapp.com",
    projectId: "threadhooks",
    storageBucket: "threadhooks.appspot.com",
    messagingSenderId: "506894190056",
    appId: "1:506894190056:web:52da31e33fcef34210d05d",
    measurementId: "G-RFMBT1B9J3"
};

// Initialize Firebase
const fireApp = initializeApp(firebaseConfig);
const fireAnalytics = getAnalytics(fireApp);
logEvent(fireAnalytics, "initialized")
const App = () => (
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <Router fireAnalytics={fireAnalytics}/>
        </I18nextProvider>
    </BrowserRouter>
);

ReactDOM.render(<App/>, document.getElementById("root"));
