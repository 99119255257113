import {lazy, useEffect} from "react";
import GeneratorBlockContent from "../../content/GeneratorBlockContent.json";
import {Analytics, logEvent} from "firebase/analytics";
import firebase from "firebase/compat";

const GeneratorBlock = lazy(() => import("../../components/GeneratorBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));

const Generator = (props: { fireAnalytics: Analytics; }) => {
    useEffect(() => {
        console.log("generator")
        logEvent(props.fireAnalytics, "generator");
    });
    return (
        <Container>
            <ScrollToTop/>
            <GeneratorBlock
                title={GeneratorBlockContent.title}
                content={GeneratorBlockContent.text}
                fireAnalytics={props.fireAnalytics}
            />
        </Container>
    );
};

export default Generator;
