import {useState} from "react";
import {Row, Col, Drawer} from "antd";
import {withTranslation} from "react-i18next";
import Container from "../../common/Container";
import {SvgIcon} from "../../common/SvgIcon";
import {Button} from "../../common/Button";
import {
    HeaderSection,
    LogoContainer,
    Burger,
    NotHidden,
    Menu,
    CustomNavLinkSmall,
    Label,
    Outline,
    Span,
} from "./styles";
import {useHistory} from "react-router-dom";

const Header = ({t}: any) => {
    const [visible, setVisibility] = useState(false);

    const showDrawer = () => {
        setVisibility(!visible);
    };

    const onClose = () => {
        setVisibility(!visible);
    };
    const history = useHistory();
    const navigateTo = (url: string) => history.push(url);
    const MenuItem = () => {
        const scrollTo = (id: string) => {
            const element = document.getElementById(id) as HTMLDivElement;
            element.scrollIntoView({
                behavior: "smooth",
            });
            setVisibility(false);
        };
        return (
            <>
                <CustomNavLinkSmall onClick={() => navigateTo("/")}>
                    <Span>{t("Home")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => navigateTo("/generator")}>
                    <Span>{t("Generator")}</Span>
                </CustomNavLinkSmall>
            </>
        );
    };

    return (
        <HeaderSection>
            <Container>
                <Row justify="space-between">
                    <LogoContainer to="/" aria-label="homepage">
                        <SvgIcon src="logo.svg" width="210px" height="90px"/>
                    </LogoContainer>
                    <NotHidden>
                        <MenuItem/>
                    </NotHidden>
                    <Burger onClick={showDrawer}>
                        <Outline/>
                    </Burger>
                </Row>
                <Drawer closable={false} visible={visible} onClose={onClose}>
                    <Col style={{marginBottom: "2.5rem"}}>
                        <Label onClick={onClose}>
                            <Col span={12}>
                                <Menu>Menu</Menu>
                            </Col>
                            <Col span={12}>
                                <Outline/>
                            </Col>
                        </Label>
                    </Col>
                    <MenuItem/>
                </Drawer>
            </Container>
        </HeaderSection>
    );
};

export default withTranslation()(Header);
