import {Suspense} from "react";
import {Switch, Route} from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {Styles} from "../styles/styles";
import Policy from "../pages/Policy";
import {Analytics} from "firebase/analytics";
import Generator from "../pages/Generator";
import Home from "../pages/Home";

const Router = (props: { fireAnalytics: Analytics; }) => {
    return (
        <Suspense fallback={null}>
            <Styles/>
            <Header/>
            <Switch>
                <Route
                    key={"Home"}
                    path={"/"}
                    exact={true}
                    render={(fireAnalytics) => <Home fireAnalytics={props.fireAnalytics}/>}
                />
                <Route
                    key={"Generator"}
                    path={"/generator"}
                    exact={true}
                    render={(fireAnalytics) => <Generator fireAnalytics={props.fireAnalytics}/>}
                />
                <Route
                    key={"Policy"}
                    path={"/policy"}
                    exact={true}
                    render={(fireAnalytics) => <Policy fireAnalytics={props.fireAnalytics}/>}
                />
            </Switch>
            <Footer/>
        </Suspense>
    );
};

export default Router;
