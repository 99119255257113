import {Row, Col} from "antd";
import {withTranslation} from "react-i18next";
import {SvgIcon} from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
    FooterSection,
    Title,
    NavLink,
    Extra,
    LogoContainer,
    Para,
    Large,
    Chat,
    Empty,
    FooterContainer,
    Language,
    Label,
    LanguageSwitch,
    LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
    href: string;
    src: string;
}

const Footer = ({t}: any) => {
    const handleChange = (language: string) => {
        i18n.changeLanguage(language);
    };

    const SocialLink = ({href, src}: SocialLinkProps) => {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={src}
                aria-label={src}
            >
                <SvgIcon src={src} width="25px" height="25px"/>
            </a>
        );
    };

    return (
        <>
            <FooterSection>
                <Container>
                    <Row justify="space-between">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Language>{t("Contact")}</Language>
                            <Para>
                                {t(`Do you have any question? Feel free to reach out.`)}
                            </Para>
                            <a href="mailto:nic@nicolashoferer.com">
                                <Chat>{t(`Let's Chat`)}</Chat>
                            </a>
                        </Col>
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Title>{t("Policy")}</Title>
                            <Large to="/policy" left="true">
                                {t("Policy")}
                            </Large>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>
            <Extra>
                <Container border={true}>
                    <Row
                        justify="space-between"
                        align="middle"
                        style={{paddingTop: "3rem"}}
                    >
                        <NavLink to="/">
                            <LogoContainer>
                                <SvgIcon
                                    src="logo.svg"
                                    aria-label="homepage"
                                    width="180px"
                                    height="80px"
                                />
                            </LogoContainer>
                        </NavLink>
                        <FooterContainer>
                            <SocialLink
                                href="https://twitter.com/NicolasHoferer"
                                src="twitter.svg"
                            />
                            <SocialLink
                                href="https://www.linkedin.com/in/nicolas-hoferer/"
                                src="linkedin.svg"
                            />
                            <SocialLink
                                href="https://www.nicolashoferer.com"
                                src="website.svg"
                            />
                        </FooterContainer>
                    </Row>
                </Container>
            </Extra>
        </>
    );
};

export default withTranslation()(Footer);
